<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm @submit="submit" :class="{submitting}">

    <h2>BILLING INFORMATION</h2>

    <OFormInput
      id="firstName"
      name="firstName"
      type="text"
      v-model="form.firstName"
      required
      placeholder="Enter your first name"
    >
      First name on card:
    </OFormInput>
    <OFormInput
      id="lastName"
      name="lastName"
      type="text"
      v-model="form.lastName"
      required
      placeholder="Enter your last name"
    >
      Last name on card:
    </OFormInput>

    <OFormInput
      id="address"
      name="address"
      type="text"
      v-model="form.address"
      required
      placeholder="Enter your address"
    >
      Billing address:
    </OFormInput>

    <OFormInput
      id="city"
      name="city"
      type="text"
      v-model="form.city"
      required
      placeholder="Enter your city"
    >
      Billing city:
    </OFormInput>

    <div id="stateGroup" class="form-group o-form__input o-tooltip-container">
      <div><label for="state">BILLING STATE</label></div>
      <select id="state" name="state" v-model="form.state" required class="o-form__input__input"
              v-on:change="validateSelect('state','stateGroup')">
        <option value="">Select ...</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District Of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
    </div>

    <OFormInput
      id="zip"
      name="zip"
      type="text"
      v-model="form.zip"
      required
      placeholder="Enter your zip"
    >
      Billing zip:
    </OFormInput>

    <PhoneNumberInput
      v-model="form.phone"
      :disabled="submitting"
      name="target-search"
      placeholder="Enter your phone number"
      required
      autocomplete="section-target tel"
      @invalid="toValid=false"
      @valid="toValid=true"
    >
      Phone number
    </PhoneNumberInput>

    <OFormTOS/>

    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Next
      </OFormSubmitButton>
    </FormButtons>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormInput from '../objects/forms/OFormInput'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import PhoneNumberInput from '../objects/forms/OFormPhoneNumberInput'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormTOS from '../objects/forms/OFormTOS'
import { mapMutations } from 'vuex'

export default {
  name: 'CFormCreditCardFraud',
  components: { OFormError, OFormSubmitButton, OFormInput, OForm, FormButtons, PhoneNumberInput, OFormTOS },
  mixins: [apiForm],
  props: {
    credits: Number
  },
  data: function () {
    return {
      form: {
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword,
        credits: this.credits,
        processor: 'inovio',
        firstName: this.$store.getters.getFirstName,
        lastName: this.$store.getters.getLastName,
        city: this.$store.getters.getCity,
        address: this.$store.getters.getAddress,
        zip: this.$store.getters.getZip,
        state: this.$store.getters.getRegion,
        phone: this.$store.getters.getPhone
      }
    }
  },
  methods: {
    ...mapMutations(['setBilling']),
    async submit () {
      this.validateSelect('state', 'stateGroup')

      this.setBilling({
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        city: this.form.city,
        address: this.form.address,
        zip: this.form.zip,
        region: this.form.state,
        phone: this.form.phone
      })

      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('billing')
      this.form.token = token

      this.$callApi(api => api.creditCardOrder(this.form))
    },
    validateSelect (id, groupId) {
      const el = document.getElementById(id)
      const group = document.getElementById(groupId)
      if (el.checkValidity()) {
        group.classList.remove('is-invalid')
      } else {
        group.classList.add('is-invalid')
      }
    }
  },
  formSubmitted (response) {
    this.$emit('fraudPass', response.orderId, this.form.firstName + ' ' + this.form.lastName)
  }
}
</script>
