<template>
  <l-default>
    <OTitle>Credit Card Checkout</OTitle>
    <OCRow class="u-mb-3">
      <div>{{email}}</div>
      <div class="u-ml-auto">{{credits}} credits</div>
    </OCRow>

    <CVerifyEmail v-if="!isActive"></CVerifyEmail>

    <div v-if="isActive" class="u-mb-3">
      <CBuyButton :credits="Number(parameters.credits)" :cost="Number(parameters.amount)"
                  :always="isAlways" :isLink="false" class="c-grid__item"></CBuyButton>
      <CFormCreditCardFraud v-if="!isFraudPass"  @fraudPass="handleFraudPass" :credits="Number(parameters.credits)" class="c-grid__item"></CFormCreditCardFraud>
      <CFormCreditCard v-if="isFraudPass" :orderId="this.orderId" :cardname="this.cardname" class="c-grid__item"></CFormCreditCard>
      <CSupportedCards />
    </div>
  </l-default>
</template>

<script>
import LDefault from '@/layouts/LDefault'
import OTitle from '../../objects/OTitle'
import { mapGetters } from 'vuex'
import OCRow from '../../objects/containers/OCRow'
import CBuyButton from '../../components/CBuyButton'
import CFormCreditCardFraud from '../../forms/CFormCreditCardFraud'
import CFormCreditCard from '../../forms/CFormCreditCard'
import CVerifyEmail from '../../components/CVerifyEmail'
import CSupportedCards from '../../components/CSupportedCards'

export default {
  name: 'PageCreditCardCheckout',
  components: {
    CSupportedCards,
    CFormCreditCardFraud,
    CFormCreditCard,
    LDefault,
    OTitle,
    CBuyButton,
    OCRow,
    CVerifyEmail
  },
  data: function () {
    return {
      isFraudPass: false,
      orderId: null,
      cardname: null
    }
  },
  computed: {
    ...mapGetters({
      credits: 'getCredits',
      isActive: 'isActive',
      email: 'getEmail'
    }),
    parameters: function () {
      return this.$route.query
    },
    isAlways: function () {
      return false
    }
  },
  methods: {
    handleFraudPass: function (orderId, cardname) {
      this.orderId = orderId
      this.cardname = cardname
      this.isFraudPass = true
    }
  }
}
</script>

<style lang="scss">
  h2 {
    color: $primary;
  }

  .c-grid {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 0 1rem;
  }

  .c-grid__title {
    margin: 0;
    padding: 0.25em 0;
    text-transform: uppercase;
  }

  .c-grid__item {
    margin: 0 0 0.5rem;
  }

  .u-ml-auto {
    margin-left: auto;
  }

  .u-mb-3 {
    margin-bottom: 1.5rem;
  }
</style>
