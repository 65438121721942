<template>
  <div class="supported-cards">
    We accept VISA and Mastercard credit cards only. If you use debit cards, your bank could decline some transactions
    for fraud reasons. If you have a declined transaction, please contact your bank to allow this payment.
    <div class="ccicons">
      <OVisa class="ccicon-supported"/>
      <OVisa3d class="ccicon-supported"/>
      <OMastercard class="ccicon-supported"/>
      <OMastercard3d class="ccicon-supported"/>
    </div>
  </div>
</template>

<script>
import OVisa from '../objects/OVisa'
import OMastercard from '../objects/OMastercard'
import OVisa3d from '../objects/OVisa3d'
import OMastercard3d from '../objects/OMastercard3d'

export default {
  name: 'CSupportedCards',
  components: { OVisa, OMastercard, OVisa3d, OMastercard3d }
}
</script>

<style lang="scss">

.supported-cards {
  text-align: center;
  color: $primary;
}

.ccicons {
  margin-top: 10px;
}

.ccicon-supported {
  height: 38px;
  width: 60px;
  margin-right: 10px;
}

</style>
